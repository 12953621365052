<template>
  <el-card shadow="always">
    <template #header>
      <el-row type="flex" justify="space-between" align="center">
        <h4>Códigos do Produto</h4>
        <el-col :md="18">
          <el-row :gutter="20" type="flex" justify="end">
            <el-autocomplete
              value-key="name"
              :value="CategoryName"
              :fetch-suggestions="fetchProductCategories"
              placeholder="Selecione uma categoria"
              @select="selectCategory"
            >
              <template #prepend>Categoria de Produto:</template>
            </el-autocomplete>
          </el-row>
        </el-col>
      </el-row>
    </template>
    <el-table
      stripe
      :cell-style="
        () => 'text-align:center;padding-left: 2px;padding-right:2px;'
      "
      :data="CompiledProducts"
      style="width: 100%; z-index: 0"
      :row-class-name="
        (scope) => (scope.row.main_product ? 'header-color' : '')
      "
    >
      <el-table-column prop="name" label="nome">
        <template #default="s">
          <h4 v-if="s.row.main_product">{{ s?.row?.name }}</h4>
          <small v-else>{{ s?.row?.name }}</small>
        </template>
      </el-table-column>
      <el-table-column label="EAN">
        <template #default="scope">
          <base-input
            v-if="!scope.row.main_product"
            v-model="scope.row.codes.ean"
            v-on:keyup.enter="saveProductMountedCode(scope.row)"
          ></base-input>
        </template>
      </el-table-column>
      <el-table-column label="QR CODE">
        <template #default="scope">
          <base-input
            v-if="!scope.row.main_product"
            v-on:keyup.enter="saveProductMountedCode(scope.row)"
            v-model="scope.row.codes.qrcode"
          ></base-input>
        </template>
      </el-table-column>
      <el-table-column label="GTIM">
        <template #default="scope">
          <base-input
            v-if="!scope.row.main_product"
            v-on:keyup.enter="saveProductMountedCode(scope.row)"
            v-model="scope.row.codes.gtim"
          ></base-input>
        </template>
      </el-table-column>
      <el-table-column label="CEST">
        <template #default="scope">
          <base-input
            v-if="!scope.row.main_product"
            v-on:keyup.enter="saveProductMountedCode(scope.row)"
            v-model="scope.row.codes.cest"
          ></base-input>
        </template>
      </el-table-column>
      <el-table-column label="NCM CONG.">
        <template #default="scope">
          <base-input
            v-if="!scope.row.main_product"
            v-on:keyup.enter="saveProductMountedCode(scope.row)"
            v-model="scope.row.codes.ncm_frozen"
          ></base-input>
        </template>
      </el-table-column>
      <el-table-column label="NCM RESF.">
        <template #default="scope">
          <base-input
            v-if="!scope.row.main_product"
            v-on:keyup.enter="saveProductMountedCode(scope.row)"
            v-model="scope.row.codes.ncm_chilled"
          ></base-input>
        </template>
      </el-table-column>
    </el-table>
  </el-card>
</template>

<script>
import { ElNotification } from "element-plus";
import BaseInput from "../components/BaseInput.vue";
export default {
  name: "PricesTable",
  components: { BaseInput },
  data: () => ({
    hasError: false,
    isLoading: false,
    categories: null,
    category: null,
  }),
  computed: {
    ShowProducts() {
      return (
        this?.category?.products.map((p) => ({ ...p, price: p.price || {} })) ||
        []
      );
    },
    CompiledProducts() {
      return this.ShowProducts.map((p) =>
        [
          {
            ...p,
            main_product: true,
          },
          p?.mounted
            .map((m) => ({
              ...p,
              codes: { ...m.codes },
              id: m.id,
              name: `${p.name} ${m.cutting ?? ""} ${m.composition ?? ""} ${
                m.storage ?? ""
              }`,
            }))
            ?.flat(),
        ]?.flat()
      )?.flat();
    },
    CategoryName() {
      return this.category?.name || "";
    },
  },
  methods: {
    saveProductMountedCode(p) {
      this.save({
        ...p,
        codes: {
          ...p.codes,
          product_mounted_id: p.id,
        },
      });
    },
    fetchProductCategories(query, callback) {
      fetch(`${this.$store.state.apiUrl}categories`, {
        credentials: "include",
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else return response.text();
        })
        .then((json) => callback(json))
        .catch(() => callback([]));
    },
    selectCategory(c) {
      this.category = c;
    },
    save(p) {
      fetch(`${this.$store.state.apiUrl}products/${p.uid}`, {
        credentials: "include",
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          codes: p.codes,
        }),
      })
        .then((r) => {
          if (r.status === 200) return r.json();
          else throw r.json();
        })
        .then(
          () =>
            ElNotification.success({ title: "Código atualizado com sucesso" }),
          (e) =>
            ElNotification.error({
              title: "Ocorreu um erro",
              message: e.message,
            })
        )
        .finally(() => this.$emit("should-update"));
    },
  },
};
</script>
<style scoped>
.el-card {
  width: 100%;
}
.el-button {
  margin-left: 10px;
}
.el-autocomplete {
  margin-top: 0px !important;
  padding-top: 0px !important;
  display: block !important;
}
.header-color {
  background-color: #c0c4cc !important;
}
</style>